<!--breadcrumb-->
<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
	<div class="breadcrumb-title pe-3">{{title}}</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
				</li>
				<li class="breadcrumb-item active" aria-current="page">{{label}}</li>
			</ol>
		</nav>
	</div>
	<!-- <div class="ms-auto">
		<div class="btn-group">
			<button type="button" class="btn btn-primary">Settings</button>
			<button type="button" class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
			</button>
			<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	
				<a class="dropdown-item" href="#" (click)="$event.preventDefault()">Action</a>
				<a class="dropdown-item" href="#" (click)="$event.preventDefault()">Another action</a>
				<a class="dropdown-item" href="#" (click)="$event.preventDefault()">Something else here</a>
				<div class="dropdown-divider"></div>	
				<a class="dropdown-item" href="#" (click)="$event.preventDefault()">Separated link</a>
			</div>
		</div>
	</div> -->
</div>
<!--end breadcrumb-->