export const Config = {
    key: 'rcx-session',
    menus: 'menus',
    // apiKey: 'e0c1-h2u3-r4c5-h6',
    rememberMe: 'rememberMe',
    context:'context'
}
export interface Country{
    id: number;
    name: string;
    code: string;
    logo: string;
    start: number;
    disabled: boolean;
}

export interface Currency{
    id: number;
    name: string;
    companyId: number;
    symbol: string;
    logo: string;
    worth: number;
    disabled: boolean;
}

export interface Timezone{
    id: number;
    name: string;
    companyId: number;
    code: number;
    lable: string;
    disabled: boolean;
}