import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  selectedItem: Menu;

  constructor(private httpClient: HttpClient) {}
  public getMenues() {
    return this.httpClient.get<Menu[]>('/api/menu/menu');
  }

  defaultMenu: Menu[] = [];

  menuItems = new BehaviorSubject<Menu[]>(this.defaultMenu);
  menuItems$ = this.menuItems.asObservable();
}
export interface Menu {
  category?: string;
  module?: string;
  privilege?: string;
  title?: string;
  type?: string;
  icon?: string
  path?: string;
  badge?: string;
  clazz?: string;
  badgeClass?: string;
  submenu?: Menu[];
  externalLink?: boolean;
}
