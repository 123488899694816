<header>
  <div class="topbar d-flex align-items-center">
    <nav class="navbar navbar-expand">
      <div class="mobile-toggle-menu"><i (click)="toggleSidebar()" class='bx bx-menu'></i>
      </div>
      <div class="search-bar">
        <div class="d-flex ">
          <div class="col-md-2 col-lg-12 mr " *ngIf="context?.persona=='ADMIN' && isCmpVisible">
            <div class="input-group">
              <span class="input-group-text bg-light" placement="bottom" container="body">
                <i class="bx bx-buildings"></i>
              </span>
              <ng-select class="rcx-ng-select ng-nav" [(ngModel)]="context.companyId" placeholder="Select Company"
                (change)="setContext()" [clearable]="false">
                <ng-option *ngFor=" let comp of companies;" [value]="comp.id">{{comp.name}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-md-2 col-lg-12 mr "
            *ngIf="(context?.persona=='HR'||context?.persona=='ACCOUNTANT') && isEmpVisible">
            <div class="input-group">
              <span class="input-group-text bg-light" placement="bottom" container="body">
                <i class="bx bx-user"></i>
              </span>
              <ng-select class="rcx-ng-select ng-nav" [(ngModel)]="context.employeeId" placeholder="Select Employee"
                (change)="setContext()" [clearable]="false">
                <ng-option *ngFor="let emp of employees|keyvalue" [value]="parseId(emp.key)">{{emp.value}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="search-bar flex-grow-1">
        <div class="position-relative search-bar-box"
          [ngClass]="{'m-auto':(context?.persona=='ADMIN' && isCmpVisible) || (context?.persona=='HR'||context?.persona=='ACCOUNTANT') && isEmpVisible}">
          <input type="text" class="form-control search-control" placeholder="Type to search..." [(ngModel)]="keyword"
            [ngbTypeahead]="getSuggestions" (selectItem)="search($event.item)" [resultTemplate]="rt"
            [inputFormatter]="formatter1">
          <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
          <ng-template #rt let-r="result" let-t="term">
            <span class="text-capitalize d-flex" (keydown.enter)="search(r)">
              <i [class]="r.icon+' me-2'"></i><span class="text-uppercase pe-2">{{r.category}}</span> | <span
                class="ps-2 text-uppercase">{{r.title}}</span>
            </span>
          </ng-template>
        </div>
      </div>
      <div class="top-menu ms-auto">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item mobile-search-icon">
            <a class="nav-link" href="#" (click)="$event.preventDefault()"> <i class='bx bx-search'></i>
            </a>
          </li>
          <li class="nav-item dropdown dropdown-large">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"> <i class='bx bx-category'></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="row row-cols-3 g-3 p-3">
                <div class="col text-center" *ngFor="let menu of shortcutMenus;" [routerLink]="menu?.path">
                  <div class="app-box mx-auto bg-light text-dark"><i [class]='menu?.icon'></i>
                  </div>
                  <div class="app-title">{{menu?.title}}</div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown dropdown-large">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
              <i class='bx bx-bell'></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="#" (click)="$event.preventDefault()">
                <div class="msg-header">
                  <p class="msg-header-title">Notifications</p>
                  <p class="msg-header-clear ms-auto">Marks all as read</p>
                </div>
              </a>
              <div class="header-notifications-list" [perfectScrollbar]>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Users<span class="msg-time float-end">14 Sec ago</span></h6>
                      <p class="msg-info">5 new user registered</p>
                    </div>
                  </div>
                </a>
                <!-- <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-danger text-danger"><i class="bx bx-cart-alt"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Orders <span class="msg-time float-end">2 min ago</span></h6>
                      <p class="msg-info">You have recived new orders</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-success text-success"><i class="bx bx-file"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">24 PDF File<span class="msg-time float-end">19 min ago</span></h6>
                      <p class="msg-info">The pdf files generated</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-warning text-warning"><i class="bx bx-send"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Time Response <span class="msg-time float-end">28 min ago</span></h6>
                      <p class="msg-info">5.1 min avarage time response</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-info text-info"><i class="bx bx-home-circle"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Product Approved <span class="msg-time float-end">2 hrs ago</span></h6>
                      <p class="msg-info">Your new product has approved</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-danger text-danger"><i class="bx bx-message-detail"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Comments <span class="msg-time float-end">4 hrs ago</span></h6>
                      <p class="msg-info">New customer comments recived</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-success text-success"><i class='bx bx-check-square'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Your item is shipped <span class="msg-time float-end">5 hrs ago</span></h6>
                      <p class="msg-info">Successfully shipped your item</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-primary text-primary"><i class='bx bx-user-pin'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New 24 authors<span class="msg-time float-end">1 day ago</span></h6>
                      <p class="msg-info">24 new authors joined last week</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-warning text-warning"><i class='bx bx-door-open'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Defense Alerts <span class="msg-time float-end">2 weeks ago</span></h6>
                      <p class="msg-info">45% less alerts last 4 weeks</p>
                    </div>
                  </div>
                </a> -->
              </div>
              <a href="#" (click)="$event.preventDefault()">
                <div class="text-center msg-footer">View All Notifications</div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item dropdown dropdown-large">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">8</span>
              <i class='bx bx-comment'></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="#" (click)="$event.preventDefault()">
                <div class="msg-header">
                  <p class="msg-header-title">Messages</p>
                  <p class="msg-header-clear ms-auto">Marks all as read</p>
                </div>
              </a>
              <div class="header-message-list" [perfectScrollbar]>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-1.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Daisy Anderson <span class="msg-time float-end">5 sec ago</span></h6>
                      <p class="msg-info">The standard chunk of lorem</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-2.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Althea Cabardo <span class="msg-time float-end">14 sec ago</span></h6>
                      <p class="msg-info">Many desktop publishing packages</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-3.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Oscar Garner <span class="msg-time float-end">8 min ago</span></h6>
                      <p class="msg-info">Various versions have evolved over</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-4.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Katherine Pechon <span class="msg-time float-end">15 min ago</span></h6>
                      <p class="msg-info">Making this the first true generator</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-5.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Amelia Doe <span class="msg-time float-end">22 min ago</span></h6>
                      <p class="msg-info">Duis aute irure dolor in reprehenderit</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-6.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Cristina Jhons <span class="msg-time float-end">2 hrs ago</span></h6>
                      <p class="msg-info">The passage is attributed to an unknown</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-7.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">James Caviness <span class="msg-time float-end">4 hrs ago</span></h6>
                      <p class="msg-info">The point of using Lorem</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-8.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Peter Costanzo <span class="msg-time float-end">6 hrs ago</span></h6>
                      <p class="msg-info">It was popularised in the 1960s</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-9.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">David Buckley <span class="msg-time float-end">2 hrs ago</span></h6>
                      <p class="msg-info">Various versions have evolved over</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-10.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Thomas Wheeler <span class="msg-time float-end">2 days ago</span></h6>
                      <p class="msg-info">If you are going to use a passage</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="#" (click)="$event.preventDefault()">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="assets/images/avatars/avatar-11.png" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Johnny Seitz <span class="msg-time float-end">5 days ago</span></h6>
                      <p class="msg-info">All the Lorem Ipsum generators</p>
                    </div>
                  </div>
                </a>
              </div>
              <a href="#" (click)="$event.preventDefault()">
                <div class="text-center msg-footer">View All Messages</div>
              </a>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="user-box dropdown">
        <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/images/avatar.png" class="user-img" alt="user avatar">
          <div class="user-info ps-3">
            <p class="user-name mb-0">{{session.name??'Hi There'}}</p>
            <p class="designattion mb-0 capitailized">{{session.role??'Admin'}}</p>
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a class="dropdown-item" routerLink="/setup/profile"><i class="bx bx-user"></i><span>Profile</span></a>
          </li>
          <li *ngIf="(context?.persona=='HR'||context?.persona=='ACCOUNTANT'||context?.persona=='EMPLOYEE')">
            <a class="dropdown-item" routerLink="/payroll/timeline"><i
                class='bx bx-video-recording'></i><span>Timeline</span></a>
          </li>
          <li *ngIf="context?.persona != 'ADMIN'">
            <div class="dropdown-divider mb-0"></div>
          </li>
          <li><a class="dropdown-item" (click)="logout()"><i class='bx bx-log-out-circle'></i><span>Logout</span></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
