import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";


import { SearchPipe } from './search.pipe';
import { AmountToWordsPipe } from './amount-to-words.pipe';



@NgModule({
  declarations:[ SearchPipe, AmountToWordsPipe],
  imports:[CommonModule],
  exports:[SearchPipe,AmountToWordsPipe]
})

export class PipeModule{}
