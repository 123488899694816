import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, observable } from 'rxjs';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private toastrService: ToastrService) { }

  public showMessage(status: number, message: string) {
    switch (true) {
      case status <= 299:
        this.toastrService.success(message, 'Successful');
        break;
      case status <= 399:
        this.toastrService.info(message, 'Alert');
        break;
      case status <= 499:
        this.toastrService.warning(message, 'Warning');
        break;
      default:
        this.toastrService.error(message, 'Error');
    }
  }

  public async convertImgToBase64(event: any): Promise<string> {
    const file = event.target.files[0];
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  }

  public removeSpecialChar(value) {
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  }
  public showPassword(value) {
    return value.type === 'password'
      ? (value.type = 'text')
      : (value.type = 'password');
  }
  public setLocalItem = (key: any, item: any) => {
    localStorage.setItem(key, JSON.stringify(item));
  };
  public getLocalItem(key: string) {
    return JSON?.parse(localStorage.getItem(key) ?? (null as any));
  }
  public encrypt(value: any) {
    return btoa(value);
  }
  public decrypt(value: any) {
    return atob(value);
  }
  public removeLocalItem(key: string) {
    localStorage.removeItem(key);
  }

  public setSessionItem(key: string, value: any) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }
  public getSessionItem(key: string) {
    return JSON?.parse(sessionStorage.getItem(key) ?? (null as any));
  }
  public removeSessionItem(key: string) {
    return sessionStorage.removeItem(key);
  }
  public clearSession() {
    return sessionStorage.clear();
  }
  public getSurnames() {
    return ['Mr', 'Mrs', 'Ms'];
  }
  public getTypes() {
    return ['asset', 'liability', 'equity', 'revenue', 'expense'];
  }
  public getMonths() {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }
  public getFullMonths() {
    const month = [
      { "id": 1, "label": 'JANUARY' },
      { "id": 2, "label": 'FEBRUARY' },
      { "id": 3, "label": 'MARCH' },
      { "id": 4, "label": 'APRIL' },
      { "id": 5, "label": 'MAY' },
      { "id": 6, "label": 'JUNE' },
      { "id": 7, "label": 'JULY' },
      { "id": 8, "label": 'AUGUST' },
      { "id": 9, "label": 'SEPTEMBER' },
      { "id": 10, "label": 'OCTOBER' },
      { "id": 11, "label": 'NOVEMBER' },
      { "id": 12, "label": 'DECEMBER' }
    ];
    return month;
  }

  public confirmation(buttonText?: string) {
    return new Observable((confirmation: Observer<any>) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: buttonText || 'Yes, delete it!',
        cancelButtonText: 'Discard',
        customClass: {
          cancelButton: 'btn btn-primary',
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(function (result) {
        if (result.value) {
          confirmation.next(result.value);
        }
      });
    });
  }
  public setToastr(status: number, text: string) {
    status == 200
      ? this.toastrService.success(text)
      : status == 400
        ? this.toastrService.warning(text, 'Alert')
        : status == 401
          ? this.toastrService.info(text, 'Alert')
          : status == 500 || status == 425
            ? this.toastrService.error(text, 'Alert')
            : status == 404
              ? this.toastrService.error(text, 'Error')
              : status === 408
                ? this.toastrService.error(text, 'Error')
                : '';
  }


  //New Common Functions
  public generateYears(count: number): number[] {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let i = 0; i < count; i++) {
      if (i !== 0) {
        years.push(currentYear - i);
      }
      years.push(currentYear + i);
    }
    return years.sort((a, b) => a - b);
  }


  private hasPrivilege(privilege: string, allowedPrivileges: string[]): boolean {
    return allowedPrivileges.includes(privilege);
  }

  private canView(privilege: string): boolean {
    return this.hasPrivilege(privilege, ['VIEW']);
  }

  private canEdit(privilege: string): boolean {
    return this.hasPrivilege(privilege, ['EDIT', 'DELETE']);
  }

  private canDelete(privilege: string): boolean {
    return this.hasPrivilege(privilege, ['DELETE']);
  }

  public getPrivileges(privilege: string): { canView: boolean; canEdit: boolean; canDelete: boolean } {
    return { canView: this.canView(privilege), canEdit: this.canEdit(privilege), canDelete: this.canDelete(privilege) };
  }

  public convertTimeToDate(timeString?: string): Date {
    const currentDate = new Date();
    const [hours, minutes] = timeString?.split(':').map(Number);
    currentDate?.setHours(hours, minutes, 0, 0);
    return currentDate;
  }

  public adjustDateByDays(days: number): Date {
    const today = new Date();
    const adjustedDate = new Date(today);
    adjustedDate.setDate(today.getDate() + days);
    return adjustedDate;
  }

  public convertToLinks(text: string): string {
    const urlPattern = /(?:https?:\/\/)?(?:www\.)?\w+\.\w{2,}(?:\/\S*)?/g;
    return text?.replace(urlPattern, function (match) {
      let url = match;
      if (!/^https?:\/\//.test(url)) {
        url = 'https://' + url;
      }
      return url;
    });
  }
}
