import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];
  private exclusions: RegExp[];

  constructor(private loaderService: LoaderService) {
    this.exclusions = [
      new RegExp('/api/auth/v1'),
      new RegExp('/api/dashboard'),
      new RegExp('/api/holiday/v1'),
      new RegExp('/api/holiday/v1/.*'),
      new RegExp('/api/company/v1'),
      new RegExp('/api/employee/v1/address'),
      new RegExp('/api/employee/v1/address'),
      new RegExp('/api/employee/v1/user'),
      new RegExp('https://api.postalpincode.in/pincode'),
      new RegExp('/api/workday/v1'),
      new RegExp('/api/workday/v1/.*'),
      new RegExp('/api/context/v1/weekday'),
      new RegExp('/api/title/v1'),
      new RegExp('/api/title/v1/.*'),
      new RegExp('/api/context/v1/level'),
      new RegExp('/api/context/v1/band'),
      new RegExp('/api/menu/module'),
      new RegExp('/api/role/v1'),
      new RegExp('/api/leave/v1'),
      new RegExp('/api/leave/v1/.*'),
      new RegExp('/api/context/v1'),
      new RegExp(`/api/regular/v1`),
      new RegExp(`/api/regular/v1/*`),
      new RegExp('/api/bank/v1'),
      new RegExp('/api/bank/v1/.*'),
      new RegExp('/api/medium/v1'),
      new RegExp('/api/medium/v1/.*'),
      new RegExp('/api/family/v1'),
      new RegExp('/api/family/v1/.*'),
      new RegExp('/api/timeline/v1'),
      new RegExp('/api/timeline/v1/.*'),
      new RegExp('/api/payroll'),
      new RegExp('/api/context/v1'),
      new RegExp('/api/absence/v1'),
      new RegExp('/api/employee/v1'),
      new RegExp('/api/profile/v1'),
      new RegExp('/api/finance/v1'),
      new RegExp('/api/appraisal/v1'),
      new RegExp('/api/appraisal/v1'),
      new RegExp('/api/setup/epochs'),
      new RegExp('/api/setup/quarters'),
    ];
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.excludes(request.url)) {
      this.requests.push(request);
      this.loaderService.isLoading.next(true);
      return Observable.create(observer => {
        const subscription = next.handle(request)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.stash(request);
                observer.next(event);
              }
            },
            err => {
              this.stash(request);
              observer.error(err);
            },
            () => {
              this.stash(request);
              observer.complete();
            });
        return () => {
          this.stash(request);
          subscription.unsubscribe();
        };
      });
    }
    return next.handle(request);
  }

  excludes(url: string,): boolean {
    // return this.excludedUrls.some(rx => rx.method.includes(method) && rx.url.test(url));
    return this.exclusions.some(rx => rx.test(url));
  }

  stash(request: HttpRequest<any>) {
    const i = this.requests.indexOf(request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }
}
