import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PipeModule } from './pipes/pipe.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './component/loader/loader.component';
import { NgxMaskModule } from 'ngx-mask';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SearchPipe } from './pipes/search.pipe';
import { ConfirmationPopupComponent } from './component/confirmation-popup/confirmation-popup.component';
import { AddressComponent } from './component/address/address.component';
import { UserComponent } from './component/user/user.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  exports: [
    SearchPipe,
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    NgbModule,
    NgbPaginationModule,
    PipeModule,
    LoaderComponent,
    NgxMaskModule,
    LayoutModule,
    ConfirmationPopupComponent,
    AddressComponent,
    UserComponent,
    NgSelectModule

  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    PerfectScrollbarModule,
    NgbPaginationModule,
    PipeModule,
    FormsModule,
    PerfectScrollbarModule,
    NgxMaskModule,
    LayoutModule,
    ReactiveFormsModule,
    NgSelectModule

  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    LoaderComponent,
    ConfirmationPopupComponent,
    AddressComponent,
    UserComponent,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
