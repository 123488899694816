import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { CompanyResolver } from './shared/resolvers/company.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/sign-in',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'Full Views' }, children: Full_ROUTES, resolve: { companyId: CompanyResolver } },
  { path: '', component: ContentLayoutComponent, data: { title: 'Content Views' }, children: CONTENT_ROUTES },
  { path: '**', redirectTo: 'auth/sign-in' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
