import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Context, Country, Currency, Timezone } from '../models/context';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  constructor(private httpClient: HttpClient) { }

  public getCountries():Observable<Country[]> {
    return this.httpClient.get<Country[]>('/api/context/v1/country');
  }
  public getCurrencies(country?: number):Observable<Currency[]> {
    if(country) return this.httpClient.get<Currency[]>(`/api/context/v1/currency?country=${country}`);
    return this.httpClient.get<Currency[]>('/api/context/v1/currency');
  }
  public getTimezones(country?: number):Observable<Timezone[]> {
    if(country) return this.httpClient.get<Timezone[]>(`/api/context/v1/timezone?country=${country}`);
    return this.httpClient.get<Timezone[]>('/api/context/v1/timezone');
  }
  public getTitles():Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/title');
  }
  public getRoles():Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/merit');
  }
  public getPrivileges():Observable<any> {
    return this.httpClient.get<any>('/api/context/v1/privilege');
  }
  public getGenders():Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/gender');
  }

  public getBloodGroups():Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/blood');
  }
  public getLevels():Observable<number[]> {
    return this.httpClient.get<number[]>('/api/context/v1/level');
  }
  public getBands() :Observable<string[]>{
    return this.httpClient.get<string[]>('/api/context/v1/band');
  }
  public getPermits():Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/permit');
  }

  //NEW API'S
  public getEmployees():Observable<Map<number,string>> {
    return this.httpClient.get<Map<number,string>>('/api/context/v1/employees');
  }
}
