

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EmployeeResolver implements Resolve<any> {

  constructor(private authService: AuthService,private toastr:ToastrService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<number> {
    const employeeId = this.authService.getEmployeeId();
    if (!employeeId) {
      // this.authService.logout();
      this.toastr.error('Employee not found')
      return EMPTY;
    }
    return of(employeeId);
  }
}