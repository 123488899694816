import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Context } from 'src/app/shared/models/context';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { Config } from 'src/app/shared/models/config';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
  public title: string;
  public label: string;
  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
  ) { }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.breadcrumbService.getBreadcrumb().subscribe((breadcrumb) => {
    //   this.title = breadcrumb.title;
    //   this.label = breadcrumb.label;
    // });
  }
}
