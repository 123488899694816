import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company, Employee, EmployeeData } from '../models/domain';
import { Menu } from './navigation.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private contextReinited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private companies$: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);
  private employees$: BehaviorSubject<EmployeeData[]> = new BehaviorSubject<EmployeeData[]>([]);
  private menus$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
  private privilege$: BehaviorSubject<string> = new BehaviorSubject<string>('VIEW');

  constructor(private commonService:CommonService) { }

  public reInitContext(inited: boolean = true) {
    this.contextReinited.next(inited);
  }

  public onContextReInit(): Observable<boolean> {
    return this.contextReinited;
  }

  public setCompanies(companies: Company[]) {
    this.companies$.next(companies);
  }

  public companies(): Observable<Company[]> {
    return this.companies$;
  }

  public setEmployees(employees: EmployeeData[]) {
    this.employees$.next(employees);
  }

  public employees(): Observable<EmployeeData[]> {
    return this.employees$;
  }

  public setMenus(menus: Menu[]) {
    this.menus$.next(menus);
  }

  public menus(): Observable<Menu[]> {
    return this.menus$;
  }

  public setPrivilege(privilege: string) {
    this.privilege$.next(privilege);
  }

  public privilege(): Observable<string> {
    return this.privilege$;
  }
}
